<script setup lang="ts">
import { StructureProps } from '@/interfaces';

const emit = defineEmits<{
  (e: 'dataManipulation'): void;
}>();

const props = defineProps<{
  input: StructureProps['input'];
  data: StructureProps['data'];
  className?: string;
}>();

const state = reactive({
  show: false,
});

const classList = computed(() => {
  return { error: props.input.error };
});
</script>

<template>
  <section class="modal-password" :style="`grid-column: ${input.grid}`">
    <common-input
      v-model="data[input.id]"
      v-bind="input"
      :type="state.show ? 'text' : 'password'"
      @key-up-enter="emit('dataManipulation')"
    ></common-input>
    <common-icon-eye-on
      v-if="!state.show"
      class-mods="cursor-pointer"
      size="24px"
      @click="state.show = true"
    ></common-icon-eye-on>
    <common-icon-eye-off
      v-else
      class-mods="cursor-pointer"
      size="24px"
      @click="state.show = false"
    ></common-icon-eye-off>
  </section>
</template>

<style scoped lang="scss">
section {
  @apply relative;
  :deep(.icon) {
    @apply absolute right-3 bottom-2;
  }
}
</style>
